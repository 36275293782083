<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ accountName }}</h1>
                <p class="text-caption text-center">Outbound email transports</p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <OutboundEmailTransportList @selected="onSelectOutboundEmailTransport" :typeChoiceList="outboundEmailTransportTypeChoices"/>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
// import { mapState } from 'vuex';
import OutboundEmailTransportList from '@/components/account-dashboard/OutboundEmailTransportList.vue';

export default {
    components: {
        OutboundEmailTransportList,
    },
    data: () => ({
        account: null,
        error: null,
        outboundEmailTransportTypeChoices: [], // will be loaded from server
    }),
    computed: {
        // ...mapState({
        //     session: (state) => state.session,
        // }),
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`SearchForm.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadOutboundEmailTransportTypeList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOutboundEmailTransportTypeList: true });
                const response = await this.$client.account(this.$route.params.accountId).outboundEmailTransportType.search(); // optional: { q: 'search term' }
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response && Array.isArray(response.list)) {
                    this.outboundEmailTransportTypeChoices = response.list; // each item has { text, value, hint }
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load outbound email transport type list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOutboundEmailTransportTypeList: false });
            }
        },
        onSelectOutboundEmailTransport({ id }) {
            this.$router.push({ name: 'account-edit-outbound-email-transport', params: { accountId: this.$route.params.accountId, outboundEmailTransportId: id } });
        },
    },
    mounted() {
        this.loadAccount();
        this.loadOutboundEmailTransportTypeList();
    },
};
</script>
